<template>
  <div>
    <b-card>
      <div>
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="2"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <h3>
              <i class="fad fa-search-dollar" /> เพิ่มเมมเบอร์
            </h3>
          </b-col>

          <!-- Search -->
          <b-col md="3" />
          <b-col
            cols="12"
            md="6"
          />
          <b-col
            md="1"
            class="p-0"
            style="margin-top: 20px"
          />
        </b-row>
      </div>
      <validation-observer ref="simpleRules">
        <b-row>
          <!-- <b-col md="4" /> -->
          <!-- <b-col md="4">
          <b-form-group
            label="ยูเซอร์ไอดี"
            label-for="customDelimiter"
          >
            <v-select
              v-model="selected"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="option"
            />
          </b-form-group>
        </b-col> -->
          <!-- <b-col md="4" /> -->

          <b-col
            md="4"
          >

            <b-form-group
              label="ชื่อ"
              label-for="customDelimiter"
            >
              <validation-provider
                #default="{ errors }"
                name="ชื่อ"
                rules="required"
              >
                <b-form-input
                  id="floating-label2"
                  v-model="name"
                  :state="errors.length > 0 ? false : null"
                  type="text"
                  placeholder=""
                />
                <small class="text-danger">{{
                  errors[0] ? `กรุณากรอกชื่อ` : ''
                }}</small>
              </validation-provider>
            </b-form-group>

          </b-col>
          <b-col
            md="4"
          >

            <b-form-group
              label="นามสกุล"
              label-for="customDelimiter"
            >
              <validation-provider
                #default="{ errors }"
                name="นามสกุล"
                rules="required"
              >
                <b-form-input
                  id="floating-label2"
                  v-model="surname"
                  :state="errors.length > 0 ? false : null"
                  type="text"
                  placeholder=""
                />
                <small class="text-danger">{{
                  errors[0] ? `กรุณากรอกนามสกุล` : ''
                }}</small>
              </validation-provider>
            </b-form-group>

          </b-col>
          <b-col
            md="4"
          ><b-form-group
            label="เบอร์โทรศัพท์"
            label-for="customDelimiter"
          >
            <validation-provider
              #default="{ errors }"
              name="เบอร์โทรศัพท์"
              rules="required"
            >
              <b-form-input
                id="floating-label2"
                v-model="tel"
                :state="errors.length > 0 ? false : null"
                type="number"
                placeholder=""
              />
              <small class="text-danger">{{
                errors[0] ? `กรุณากรอกเบอร์โทรศัพท์` : ''
              }}</small>
            </validation-provider>
          </b-form-group>
          </b-col>
          <b-col
            md="4"
          ><b-form-group
            label="รหัสผ่าน"
            label-for="customDelimiter"
          >
            <validation-provider
              #default="{ errors }"
              name="รหัสผ่าน"
              rules="required"
            >
              <b-form-input
                id="floating-label2"
                v-model="password"
                :state="errors.length > 0 ? false : null"
                type="password"
                placeholder=""
              />
              <small class="text-danger">{{
                errors[0] ? `กรุณากรอกรหัสผ่าน` : ''
              }}</small>
            </validation-provider>
          </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="ธนาคาร"
              label-for="customDelimiter"
            >
              <v-select
                v-model="bank"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="banklist"
                :reduce="name => name.bankcode"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="4"
          ><b-form-group
            label="เลขที่บัญชี"
            label-for="customDelimiter"
          >
            <b-form-input
              id="floating-label2"
              v-model="acc_no"
              type="number"
              placeholder=""
            />
          </b-form-group>
          </b-col>
          <b-col
            md="4"
          ><b-form-group
            label="เบอร์ Truemoney Wallet"
            label-for="customDelimiter"
          >
            <b-form-input
              id="floating-label2"
              v-model="trueaccno"
              type="number"
              placeholder=""
            />
          </b-form-group>
          </b-col>
          <b-col
            md="4"
          ><b-form-group
            label="Line ID"
            label-for="customDelimiter"
          >
            <b-form-input
              id="floating-label2"
              v-model="line_id"
              type="text"
              placeholder="line id"
            />
          </b-form-group>
          </b-col>
          <b-col
            md="4"
          ><b-form-group
            label="เปอร์เซ็นต์คอมมิชชั่น"
            label-for="customDelimiter"
          >
            <b-form-input
              id="floating-label2"
              v-model="discount"
              type="number"
              placeholder="เปอร์เซ็นต์คอมมิชชั่น"
            />
          </b-form-group>
          </b-col>

          <b-col
            md="8"
          ><b-form-group
            v-if="UserData.agent_id === 999"
            label="การโอนเงินเข้าครั้งแรก"
            label-for="customDelimiter"
          >
            <b-form-select
              v-model="admintransfer"
              :options="optionsDep"
              size="sm"
              class="mt-1"
            />
          </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <b-button
                variant="gradient-primary"
                @click.prevent="validationForm"
              >
                <i class="fad fa-cloud-download" />
                บันทึกข้อมูล
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  // BInputGroupAppend,
  BRow,
  BCol,
  // BInputGroupPrepend,
  // BModal,
  BCard,
  // BTable,
  // BAvatar,
  BFormGroup,
  BFormSelect,
  // BPagination,
  // BInputGroup,
  BFormInput,
  BButton,
  // BCardBody,
  VBToggle,
  // BOverlay,
  // BIconController,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
// import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import { kFormatter } from '@core/utils/filter'
// import moment from 'moment-timezone'
// import flatPickr from 'vue-flatpickr-component'
// import VueApexCharts from 'vue-apexcharts'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    // BInputGroupAppend,
    BRow,
    BCol,
    // BInputGroupPrepend,
    // BModal,
    BCard,
    // BTable,
    // BAvatar,
    BFormGroup,
    BFormSelect,
    // BPagination,
    // BInputGroup,
    BFormInput,
    // BInputGroupAppend,
    BButton,
    // BCardBody,
    // StatisticCardWithAreaChart,
    // // VueApexCharts,
    // BOverlay,
    // BIconController,
    vSelect,
    // flatPickr,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      show: false,
      required,
      banklist: [],
      name: '',
      password: '',
      surname: '',
      bank: '',
      tel: '',
      acc_no: '',
      trueaccno: '',
      status: '',
      line_id: '',
      discount: '',
      admintransfer: null,
      // options: [
      //   { value: 1, text: 'Active: เข้าเล่นได้ปกติ' },
      //   { value: 2, text: 'Locked: เข้าสู่ล็อบบี้ไม่ได้ เข้าเล่นไม่ได้' },
      //   { value: 0, text: 'Suspended: เข้าสู่ล็อบบี้ได้ แต่เข้าเล่นไม่ได้' },
      // ],
      optionsDep: [
        { value: 0, text: 'โอนผ่านบัญชีลูกค้าปกติ' },
        { value: 1, text: 'call center โอนผ่าน SCB เลขบัญชี ***7913 หรือ ***6330' },
      ],
      UserData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  created() {
    this.$http.get('/analytics/data').then(response => {
      this.data = response.data
    })
  },
  mounted() {
    // Set the initial number of items
    // this.totalRows = this.items.length
    // this.Get_userlist()
    // this.getToday()
    // this.getPro()
    this.Get_bank()
    // this.gethistory()
  },
  methods: {
    kFormatter,
    Get_bank() {
      this.$http
        .get('bank/list')
        .then(response => {
          this.banklist = response.data
        })
        .catch(error => console.log(error))
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const params = {
            role: 0,
            tel: this.tel,
            passwordweb: this.password,
            password: this.password,
            fname: this.name,
            sname: this.surname,
            bank: this.bank,
            acc_no: this.acc_no,
            trueaccno: this.trueaccno,
            discount: this.discount ? this.discount : 0,
            agent_id: this.UserData.agent_id,
          }
          // console.log(params)
          this.$http
            .post(
              'member/store',
              params,
            )
            // eslint-disable-next-line no-unused-vars
            .then(response => {
              // console.log(response)
              this.show = false
              this.Success('เพิ่มเมมเบอร์สำเร็จ')
              this.$router.push({ name: 'member' })
            })
            .catch(error => {
              console.log(error)
              this.SwalError(error.response.data.message)
            })
        }
      })
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #000">Error!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-invoice.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.form-item-section {
  background-color: $product-details-bg;
}
</style>
